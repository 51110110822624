export const COMMUNITY_USER_ACTION_BUTTON_CREATEMEETING_FRAGMENT = `
  fragment communityUserActionButtonCreatemeetingFragment on CommunityUser {
    typename: __typename
    uid
    firstName
    lastName
    _ourConnection(myUid: "%authUser%") {
      uid
      linkState
      connectedUser {
        uid
        firstName
        lastName
        pictureFileResource {
          schemaCode
          path
        }
      }
      user {
        uid
        firstName
        lastName
        pictureFileResource {
          schemaCode
          path
        }
      }
    }
    _actions (actions: ["CAN_MEET"]) {
      value
      key
    }
  }
`;
